.privacy-policy {
  margin-top: var(--navbar-height);
  padding-block: 3rem;
  padding-inline: 4rem;
}
h1{
  margin-bottom: 20px;
  font-weight: bold;
}

.accordion-section {
  margin-bottom: 10px;
}
.policy-changed-date{
    font-weight: bold;
}
.accordion-button {
  background-color: #007bff;
  color: white;
  padding: 10px;
  width: 100%;
  border: none;
  cursor: pointer;
  text-align: left;
  outline: none;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.accordion-button:hover {
  background-color: #0056b3;
  color: white;
}

.accordion-content {
  padding: 15px;
  background-color: #f1f1f1;
  border-left: 4px solid #007bff;
}

@media (max-width : 425px) {
    .privacy-policy{
        padding-inline: 1.5rem !important;
    }
}