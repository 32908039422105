.testimonials-container {
    text-align: center;
    background-color: #122b41;
    color: #000000;
    padding-block: 6rem;
    padding-top: var(--navbar-height);
}

.testimonials-container h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: white;
}

.testimonials {
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
}

