
.add-more-btn{
    font-size: 20px;
    font-weight: 700;
    height: 75px;
}
.add-more-btn:hover{
    background-color: bisque;
}
.mentor-pricing{
    align-items: center;
}
.delete-sessions{
    justify-content: space-between;
}
.delete-icon{
    display: none;
}
.mentor-pricing-card:hover .delete-icon{
    display: inline;
}
.edit-delete{
    margin-left: 5px;
}