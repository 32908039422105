.book-time-slot-container {
    display: flex;
    gap: 20px;
    border-radius: 15px;
    margin: 0 auto;
  }
  
  @media (max-width:800px){
    .book-time-slot-container{
      flex-direction: column;
    }
  }
  .mentorship-details {
    flex: 0.8;
    padding: 20px;
    border-radius: 15px;
  }
  
  .back-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .mentor-info {
    text-align: left;
  }
  
  .mentor-info h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .mentor-price {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .mentor-price span {
    display: block;
  }
  
  .mentor-info p {
    font-size: 14px;
    color: #555;
  }
  
  .time-slot-selection {
    flex: 1;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.308);
    padding: 20px;
    border-radius: 15px;
  }
  
  .time-slot-selection h3 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .date-selection, .time-selection {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 20px;
  }
  
  .date-button, .time-button {
    border-radius: 5px;
    max-width: 90px;
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: unset;
    color: black;
  }
  
  .date-button.selected, .time-button.selected, .date-button:hover, .time-button:hover {
    background-color: #ffc107;
    color: #fff;
  }
  
  .confirm-button {
    width: 100%;
    padding: 12px 0;
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  