/* Mentor details profile card */
.card-shadow {
  border: 1px solid #ccc;
  border-radius: 20px;
}
.mentor-details {
  margin-top: var(--navbar-height);
  padding-inline: 5rem;
  padding-top: 2rem;
  padding-bottom: 3.5rem;
  gap: 10px;
}
.mentor-details-profile{
    padding-bottom: -100px;
}
.mentor-details-profile .upper {
  background: rgb(26, 19, 147);
  background: linear-gradient(
    90deg,
    rgba(26, 19, 147, 1) 16%,
    rgba(24, 48, 223, 1) 30%,
    rgba(9, 71, 201, 1) 70%,
    rgba(8, 33, 91, 1) 96%
  );
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 250px;
}
.location-rating-lang{
  gap: 20px;
}
.mentor-details-profile .lower{
    padding-inline: 20px;
    padding-bottom: 20px;
}
.mentor-details-image img{
    height: 150px;
    width: 130px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;

}
.mentor-details-image{
    margin-left: 2rem;
    position: relative;
    top: 170px;
}
.middle{
    padding: 15px;
    gap: 10px;
    justify-content: end;
    position: relative;
    z-index: 100;
}
.middle span{
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    font-weight: 500;
}
.middle span:hover{
    cursor: pointer;
    background-color: #ccc;
}
.mentor-details-profile .lower{
  justify-content: space-between;
}
.lower-left{
  color: #ccc;
  font-weight: 500;
  max-width: 700px;
}
.lower-left h4{
  color: black;
}
.mentor-detail-bio{
  padding-bottom: 1rem;
  padding-top: 8px;
  font-size: .875rem;
  line-height: 1.25rem;
  color: rgb(102 112 133/1);
}
.lower-right{
  color: #ccc;
  font-weight: 500;
  gap: 8px;
  max-width: 350px;
}
.lower-right .location{
  font-size: 16px;
  display: flex;
  align-items: start;
}
.lower-right .location-icon{
  margin-top: 6px;
}

/* Responsive */
@media (max-width: 440px) {
  .mentor-details-image{
    justify-content: center;
    margin-left: 0 !important;
    top: 70px !important;
  }
  .mentor-details{
    padding-inline: 1rem !important;
  }
  .middle{
    justify-content: center;
    margin-top: 4rem;
  }
  .mentor-details-profile .upper{
    height: 150px !important;
  }
}

@media (max-width: 768px) {
  .lower{
    flex-wrap: wrap;
    gap: 40px;
  }
}
@media (max-width: 1024px) {
  .lower-left{
    max-width: 500px;
  }
}
@media(max-width: 1100px) {
  .mentor-details{
    padding-inline: 2rem;
  }
}
