.page-not-found {
    text-align: center;
    margin-top: 100px;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .page-not-found h1 {
    font-size: 120px;
    color: #ff4040;
    margin-bottom: 20px;
  }
  
  .page-not-found h2 {
    font-size: 36px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .page-not-found p {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .home-link {
    font-size: 18px;
    color: #fff;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .home-link:hover {
    background-color: #0056b3;
    color: white;
  }
  