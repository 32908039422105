.margin-top{
  margin-top: 2.5rem;
}

.login {
  justify-content: center;
  padding: 1rem;
  flex-wrap: wrap;
  padding-top: var(--navbar-height);
}
.codequeue {
  font-size: 30px;
}
.left {
  width: 30rem;
  gap: 31px;
  padding-block: 15px;
  padding-inline: 2.5rem;
}
.left-top {
  justify-content: space-between;
}

.left-mid {
  gap: 40px;
  /* align-items: center; */
}
.left-mid-btn {
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
}
.btn {
  font-size: 16px;
  font-weight: 450;
  gap: 8px;
}
.google-icon {
  height: 25px;
}
.linkedin-icon {
  color: #0a66c2;
}
.btn span {
  font-weight: 700;
}
form input {
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-weight: 550;
  padding: 5px;
}
form label {
  font-weight: 500;
}

.submit-btn {
  margin-block: 1.5rem;
}

/* divider line  */

.divider {
  display: flex;
  align-items: center;
}

.divider-line {
  flex: 1;
  height: 1px;
  background-color: #ccc; /* Color for the divider line */
  border: none;
}

.divider-text {
  color: #888; /* Color for the "or" text */
  font-weight: bold;
  /* margin-inline: 2px; */
}

.forgot-password {
  text-decoration: none;
  text-align: center;
  /* font-weight: 500; */
}

.right {
  width: 30rem;
  padding-top: 2.5rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}
.right img {
  width: 30rem;
}
/* Validation  */

.validation {
  font-size: 14px;
  color: red;
}
/* responsive */

@media (max-width: 425px) {
  .left-mid {
    align-items: center;
  }
  .right img {
    width: 16rem !important;
  }
  .left-mid p,h1{
    text-align: center;
  }
}

/* Medium Devices (Tablets) */
@media (max-width: 768px) {
  /* .left {
    width: 20rem;
    padding-inline: 1rem;
  } */
  .left-mid {
    align-items: center;
  }
  .right img {
    width: 18rem;
  }
  .left-mid p,h1{
    text-align: center;
  }
}
@media (max-width: 1024px) {
  .left {
    width: 25rem;
    padding-inline: 1rem;
  }
  .left-mid {
    align-items: center;
  }
  .left-mid p,h1{
    text-align: center;
  }
}
@media(max-width: 1200px) {
  .left {
    width: 30rem;
    padding-inline: 1rem;
  }
  .login {
    padding-inline: 0;
  }
  .right img {
    width: 30rem;
  }
}
