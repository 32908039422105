@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* utility classes */
:root {
  --navbar-height: 4rem;
  --primarty-text-color: blue;
  --secondary-text-color: rgb(32, 35, 41);
  --primary-text-color: rgb(210, 215, 221);
}

.App {
  max-width: 1800px;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.container {
  padding-left: 60px;
  padding-right: 35px;
}

/* primary button  */
.primary-btn {
  text-decoration: none;
  background-color: var(--primarty-text-color);
  border-radius: 5px;
  text-align: center;
  color: white;
  font-weight: bold;
  padding-inline: 15px;
  padding-top: 7px;
  padding-bottom: 10px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.primary-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  z-index: 1;
  transition: all 0.3s ease-in-out;
  animation: slide 2s linear infinite;
}

@keyframes slide {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

.primary-btn:hover::before {
  background: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.7),
    transparent
  );
}
.primary-btn:hover {
  background-color: rgb(13, 13, 145);
  color: white;
}

/* Secondary button  */
.secondary-btn {
  text-decoration: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  color: black;
  font-weight: bold;
  padding-inline: 15px;
  padding-top: 7px;
  padding-bottom: 10px;
  cursor: pointer;
}
.secondary-btn:hover {
  background-color: rgb(214, 214, 214);
}

/* hover effect */

.hover:hover {
  color: var(--primarty-text-color);
  font-weight: 500;
  cursor: pointer;
}

.push-navbar {
  margin-top: var(--navbar-height);
}

.Toastify__close-button {
  width: 8%;
}

a {
  text-decoration: none;
  color: black;
}

.bg-clr {
  background: linear-gradient(
    45deg,
    rgba(0, 128, 255, 0.1),
    rgba(0, 255, 128, 0.1),
    rgba(255, 0, 128, 0.1)
  );
}

/* User below brakpoint */
/* Media query */

@media (max-width: 425px) {
}

@media (max-width: 768px) {
}
@media (max-width: 1024px) {
}
@media (max-width: 1200px) {
}
