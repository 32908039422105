.we-offer{
    padding-block: 5rem;
    padding-inline: 2rem;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
    background-color: bisque;
}
.we-offer-header{
    text-align: center;
    background-color: bisque;
    margin: 0;
    padding-top: 4rem;
}
.we-offer-card{
    background-color: white;
    padding: 1.5rem;
    border-radius: 20px;
    gap: 50px;
}

.we-offer-left{
    font-size: 20px;
    font-weight: 700;
}
.we-offer-right{
    gap: 40px;
    background: #efeff0;
    padding: 20px;
    border-radius: 20px;
}
.we-offer-right div{
    font-weight: 700;
}