
.glass-effect {
    background: linear-gradient(135deg, transparent, rgb(255, 255, 232));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    /* box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37); */
  }
  .position-relative {
    position: relative;
  }
  
  .floating-input {
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    outline: none;
    background: none;
    box-shadow: none;
    border: none; /* Remove border */
    border-bottom: 2px solid #ccc; /* Only bottom border for design */
    transition: border-color 0.2s ease;
  }
  
  .floating-input:focus {
    border-bottom-color: #007bff; /* Change color on focus */
    outline: none;
  }
  
  .floating-label {
    position: absolute;
    top: 1.5rem;
    left: 0;
    color: #6c757d;
    transition: all 0.2s ease;
    pointer-events: none;
    font-size: 1rem;
  }
  
  .floating-input:focus + .floating-label,
  .floating-input:not(:placeholder-shown) + .floating-label {
    top: 0.2rem;
    left: 0;
    font-size: 0.75rem;
    color: #007bff;
  }
  
  textarea.floating-input {
    padding-top: 1.75rem;
  }
  
  .no-border {
    border-radius: 0; /* Removes rounded corners */
  }
  .form-container {
    max-width: 500px;
    margin: auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-input,
  .form-textarea {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ddd;
    resize: none;
  }
  
  .form-textarea {
    height: 100px;
  }
  
  .form-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .form-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }