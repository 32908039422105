.select-sty {
  border: 1px solid #ccc;
  border-radius: 5px;
  font-weight: 600;
  padding-inline: 15px;
  padding-top: 7px;
  padding-bottom: 10px;
  cursor: pointer;
  outline: none;
  height: 50px;
  /* margin-bottom: 2rem; */
}

.mentor-profiles {
  margin-top: var(--navbar-height);
  padding-inline: 5rem;
  padding-block: 3.5rem;
  gap: 40px;
}

.mentor-profiles-left {
  width: 350px;
  /* background-color: blue; */
}
.filter-type{
    color: rgb(20 23 31 / 1);
    font-weight: 500;
    margin-top: 2rem;
}
.sort-by:hover {
  background-color: white !important;
  cursor: auto;
}
.sort-by-label {
  margin-top: 4px;
  width: 80px;
  font-weight: 400 !important;
}
.sort-by select {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  font-weight: 600;
}
.sort-by select:hover {
  cursor: pointer;
}

.filter-by-text{
    margin-bottom: 1rem;
    margin-top: 2rem;
}

/* offering mentorship */

.offering-mentorship input{
    padding: 0;
    margin: 0;
    width: 15px;
}
.offering-input{
    gap: 10px;
}




/* ------------------------mentor profiles --------------------- */
.profile-card-left{
    background-size: cover;
    background-position: center;
}
.profile-card-left img{
  width: 130px;
  height: 150px;
}

.profile-card{
  gap: 20px;
}
.profile-shadow{
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  align-items: center;
  font-weight: 500;
}
.profile-shadow-left{
  gap: 15px;
  padding: 20px;
  padding-right: 0;
}
.profile-card-right{
  justify-content: space-between;
}
.loca-rating-lang{
  gap: 20px;
}
.location{
  font-size: .875rem;
  display: flex;
  align-items: start;
  color: rgb(102 112 133/1);
}
.location-icon{
  font-size: .875rem !important;
  font-weight: 600;
  color: black;
  margin-top: 3px;
}
.mentor-profiles-right{
  gap: 20px;
}
.mentor-profiles-right :hover{
  cursor: pointer;
}
.mentor-bio{
  padding-top: 8px;
  font-size: .875rem;
  line-height: 1.25rem;
  color: rgb(102 112 133/1);
}
.mentor-bio span{
  font-weight: 600;
  color : rgb(21 112 239/1)
}
.mentor-skills{
  gap: 10px;
  font-size: .825rem;
  color: rgb(59, 65, 78);
  font-weight: 600;
  flex-wrap: wrap;
}
.skills-key{
  border: 1px solid #ccc;
  padding-inline: 5px;
}

/* min price book session */
.profile-card-min-price{
  gap: 10px;
  width: 175px;
  background-color: white;
  align-items: center;
  margin : 20px;
  justify-content: end;
  padding-block: 20px;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

/* media query  */
@media (max-width: 425px) {
}
@media (max-width: 570px) {
  .mentor-bio{
    display: none;
  }
  .mentor-skills{
    display: none;
  }
  .loca-rating-lang{
    flex-direction: column;
    align-items: center;
    gap: 5px !important;
  }
}
@media (max-width: 720px) {
  .profile-card{
    flex-wrap: wrap;
    justify-content: center;
  }
  .card-content{
    align-items: center;
  }
  .loca-rating-lang{
    justify-content: center;
  }
}
@media (max-width : 870px) {
  .profile-shadow{
    flex-wrap: wrap;
    justify-content: center;
  }
  .profile-card-min-price{
    display: none;
  }
}
@media (max-width: 1024px) {
  .mentor-profiles-left{
    display: none;
  }
}

@media(max-width: 1340px) {
  .mentor-profiles{
    padding-inline: 3rem;
  }
}