.refund-policy{
    margin-top: var(--navbar-height);
    padding-top: 2rem;
    padding-bottom: 5rem;
    padding-inline: 5rem;
}
.refund-policy p{
    padding-top: 2px;
    padding-left: 0.7rem;
    color: hsl(240 6% 10%);
    line-height: 1.75rem;
}
.refund-policy h3{
    font-weight: bold;
}
strong{
    font-size: 18px;
    font-weight: bolder;
}
.email{
    font-weight: bold;
}

@media (max-width: 1024px ) {
    .refund-policy{
        padding-inline: 2rem;
    }
}

