* {
    padding: 0;
    margin: 0;
  }
  .footer {
    justify-content: space-around;
    background-color: var(--secondary-text-color);
    padding: 1.6rem 0.5rem;
    color: white;
    flex-wrap: wrap;
  }
  .footer-brand-text {
    font-size: 2rem;
    font-weight: 700;
  }
  .footer-brand-text .flex{
    align-items: center;
  }
  .footer-brand-text img{
    height: 30px;
  }
  .td {
    display: inline-block;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: var(--primary-text-color);
    font-weight: 400;
  }
  header {
    color: white; /*rgb(171 175 181 /1);*/
    font-size: 18px;
    font-weight: 550;
    margin-bottom: 20px;
  }
  .common {
    gap: 16px;
  }
  
  .footer-social-media {
    gap: 16px;
    color: var(--primary-text-color);
  }
  .footer-social-media a{
    text-decoration: none;
    color: white;
  }
  .copyright {
    padding: 1rem 0.5rem;
    color: var(--primary-text-color);
    font-weight: 500;
    text-align: center;
    background-color: var(--secondary-text-color);
  }
  
  /* responsive  */
  
  @media (max-width: 320px) {
  }
  
  /* Small Devices (Mobile Phones) */
  @media (max-width: 426px) {
    .copyright{
      font-size: 14px;
    }
  }
  
  /* Medium Devices (Tablets) */
  @media(max-width: 768px) {
      .footer {
          flex-direction: column;
          align-items: center;
          gap: 20px;
        }
        .common{
          align-items: center;
        }
        header{
          text-align: center;
        }
        .footer-brand-text{
          text-align: center;
        }
  }
  
  /* Large Devices (Desktops) */
  @media (min-width: 769px) and (max-width: 1024px) {
  }
  