
.book-time-slot-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top of other content */
  }
  
  .book-time-slot-popup{
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 700px;
    max-height: 80vh;
    overflow-y: auto;
  }
  .time-slot-available-slots{
    gap: 20px;
    flex-wrap: nowrap;
    overflow-x: auto; 
    padding: 10px 0;
    max-width: 100%;
    flex-shrink: 0;
    align-items: center;
  }
  .time-slot-available-dates{
    border: 1px solid #ccc;
    border-radius: 10px;
    gap: 3px;
    padding-block: 10px;
    padding-inline: 5px;
    flex: 0 0 auto;
    min-width: 100px;
  }
  .time-slot-available-dates .day{
    font-size: 10px;
    font-weight: 700;
    color: rgb(102 112 133/1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 40px;
  }
  
  .time-slot-available-dates .date{
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
    color: rgb(16 24 40/1)
  }
  .time-slot-available-dates .slots{
    font-size: 12px;
    color: rgb(48 209 88/1);
    font-weight: 700;
  }
  .time-slot-available-text{
    margin-bottom: 1rem;
    font-weight: 600;
    text-align: start;
  }
  .time-slot-divider{
    margin-top: 1.5rem;
    height: 0.5px;
    background-color: #ccc;
  }
  .time-slot-available-date{
    font-weight: 600;
    margin-block: 1rem;
    justify-content: space-between;
  }
  .time-slot-available-time{
    font-weight: 700;
    text-align: center;
  }
  
  .book-now{
    margin-top: 2rem;
    flex-shrink: 0; /* Prevent button from shrinking */
  }
  .time-slot-close-icon{
    text-align: end;
    position: relative;
    top: -25px;
    right: -20px;
    cursor: pointer;
  }

  .time-slot-is-date-selected{
    background-color: #9c9aa2;
  }

  .remove-date-icon{
    color: red;
    display: none;
  }
  .time-slot-available-dates:hover .remove-date-icon{
    display: inline;
  }

