.header h2 {
    font-size: 28px;
    margin-bottom: 20px;
}

.new-template {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.template-info {
    background-color: #e6e0ff;
    padding: 20px;
    border-radius: 10px;
}

.template-info h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.template-info p {
    font-size: 16px;
    margin-bottom: 20px;
}

.template-info .image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-button {
    background-color: rgb(5, 105, 5);
    color: white;
    width: 80px;
    padding: 7px 0px;
    margin-left: 40px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.new-bookings {
    padding: 10px 80px;
    /* background-color: #666; */
}

.new-bookings .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: tomato; */
}

.help-section h2 {
    font-size: 22px;
    margin-bottom: 20px;
}

.help-options {
    display: grid;
    grid-template-columns: auto auto auto;
}

.help-card {
    width: 90%;
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    border-radius: 10px;
    border: 1px solid gainsboro;
    overflow: hidden;
    /* To ensure rounded corners are maintained */
}

.image-container {
    background-color: #e0e0e0;
    height: 110px;
    padding: 10px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 20px;
}

.image-container img {
    display: block;
    max-width: 100%;
    height: auto;
}

.text-container {
    background-color: white;
    padding: 20px 5px 0px;
}

.help-card h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.help-card a {
    font-size: 16px;
    color: rgb(36, 36, 36);
    text-decoration: underline;
}

.help-card p {
    font-size: 14px;
    margin-top: 10px;
    color: #666;
}


@media (max-width:1250px) {
    .help-options {
        grid-template-columns: auto auto;
        gap: 20px;
    }
}

@media (max-width:890px) {
    .help-options {
        grid-template-columns: auto;
        gap: 20px;
    }

    .new-template {
        flex-direction: column;
    }
}