/* ComingSoon.css */

.coming-soon-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top of other content */
  }
  
  .coming-soon-popup {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .coming-soon-popup h1 {
    font-size: 2rem;
    color: #333;
  }
  
  .coming-soon-popup p {
    font-size: 1rem;
    color: #666;
    margin: 1rem 0;
  }
  
  .coming-soon-popup button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .coming-soon-popup button:hover {
    background-color: #0056b3;
  }
  