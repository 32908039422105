/* src/Dashboard.css */
.dashboard {
    display: flex;
    font-family: Arial, sans-serif;
    overflow: hidden;
    padding-top: var(--navbar-height);
}



.main-content {
    flex: 1;
    padding: 20px 30px;
}

