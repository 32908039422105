.signup-left{
    gap: 20px !important;
}
.signup-left-top{
    gap: 20px !important;
}
.signup-btn{
    margin-block: 10px !important;
}

.otp input{
    height: 40px;
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
}
.otp-send{
    height: 40px;
    width: 80px;
    background-color: #ccc;
    color: rgb(37, 36, 36);
    justify-content: center;
    align-items: center;    
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-weight: 700;
}
.otp-send:active{
    background-color: #afacac;
    color: white;
}
.otp-send:hover{
    cursor: pointer;
}

.otp-verify input{
    height: 25px;
    width: 70px;
    font-size: 12px;
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
}
.otp-toggle{
    display: none;
}
.otp-verify span{
    display: inline-block;
    height: 25px;
    width: 60px;
    text-align: center;
    background-color: green;
    color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.otp-verify span:active{
    background-color: rgb(4, 98, 4);
    color: white;
}
.otp-verify span:hover{
    cursor: pointer;
}

.is-full-details{
    display: none;
}
.as-mentor{
    margin-top: 1rem;
}
.s-b-btn{
    gap: 20px;
}