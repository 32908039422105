.recent-review {
  padding: 20px;
}
.recent-review-top {
  font-weight: 700;
}
.all-review {
  color: rgb(37 99 235 / 1);
  cursor: pointer;
}
.recent-review-middle {
  padding-block: 10px;
  /* padding-right: 12rem; */
}
.recent-review-bottom {
  justify-content: space-between;
}
.candidate-profile {
  align-items: center;
  gap: 5px;
}
.candidate-profile-top {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
}
.seprator {
  align-items: center;
}
.left-seprator {
  flex: 1;
  height: 1px;
  background-color: #ccc;
  border: none;
}
.content-seprator {
  flex: 1;
  height: 1px;
  background-color: #ccc;
  border: none;
  margin-block: 1.5rem;
}
.show-all-review {
  margin-inline: 20px;
  background-color: #e5e7eb;
  border-radius: 10px;
  padding-inline: 10px;
  padding-block: 4px;
}
.show-all-review:hover {
  cursor: pointer;
}
