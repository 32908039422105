.add-more-session-popup-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .add-more-session-popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 90%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  /* css */

  .add-more-session-text {
    margin-top: 0;
    font-size: 1.5rem;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    /* color : rgb(153 153 153 / 1);  */
    color: #555;;
    font-weight: bold;   
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-weight: 500;
  }
  .form-group input:focus{
    border-color: blue; 
    outline: none; 
  }
  .button-group {
    justify-content: space-between;
    margin-top: 20px;
  }
  