* {
  padding: 0;
  margin: 0;
}
.nav {
  flex: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;

  position: fixed;
  top: 0; 
  width: 100%; 
  z-index: 1000; 
  background-color: #ffffff; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.nav-left {
  gap: 20px;
  padding-left: 4rem;
  align-items: center;
  height: var(--navbar-height);
}
.nav-right {
  gap: 20px;
  align-items: center;
  height: var(--navbar-height);
  flex: auto;
  justify-content: space-between;
}

/* show and hide navbar content */
.contentToggle {
  position: fixed;
  left: 0px;
  transition: 0.2s;
  padding: 20px;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.LeftContentToggle{
  padding-bottom: 10px;
  flex-direction: column !important;
  align-items: center;
}
.nav-right-left {
  gap: 20px;
}
.nav-right-left a {
  text-decoration: none;
  color: black;
}
.nav-right-right {
  gap: 20px;
}
.brand-text {
  font-size: 30px;
  font-weight: bold;
  padding-right: 30px;
  padding-bottom: 5px;
}
.brand-text .flex{
  align-items: center;
}
.brand-text img{
  height: 30px;
}
.toggleBtn {
  display: none;
  height: 4rem;
  position: absolute;
  right: 20px;
  align-items: center;
}
/* Responsive */

/* Extra Small Devices (Mobile Phones) */
@media (max-width: 320px) {

}

/* Small Devices (Mobile Phones) */
@media (max-width: 426px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .nav-left{
    padding-left: 1px;
  }
  .nav-right {
    display: none;
  }
  .toggleBtn {
    display: flex;
    align-items: center;
  }
  .contentToggle {
    height: 100%;
    background-color: #ffffff;
    border-radius: 0;
  }
}

/* Medium Devices (Tablets) */
@media (min-width: 426px) and (max-width: 890px) {
  .container {
    padding-left: 40px;
    padding-right: 20px;
  }
  .nav-left{
    padding-left: 2rem;
  }
  .nav-right {
    display: none;
  }
  .toggleBtn {
    display: flex;
    align-items: center;
  }
  .contentToggle {
    height: 100%;
    background-color: #ffffff;
    border-radius: 0;
  }
}

/* Large Devices (Desktops) */
@media (min-width: 781px) and (max-width: 1024px) {
  .nav-left{
    padding-left: 3rem;
  }
}
