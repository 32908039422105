.mentors-technical-skills{
    padding: 30px;
}

.mentors-technical-skills .tech-skills{
    border: 1px solid #ccc;
    padding-block: 3px;
    padding-inline: 10px;
    border-radius: 20px;
}
.mentors-technical-skills .flex{
    gap: 10px;
    flex-wrap: wrap;
}