.payments-container {
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  h2 {
    margin-bottom: 15px;
  }
  
  .payment-history table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .payment-history th,
  .payment-history td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .saved-cards .card-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .card-info {
    display: flex;
    flex-direction: column;
  }
  
  .card-actions {
    display: flex;
    align-items: center;
  }
  
  .card-actions button {
    padding: 5px 10px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .card-actions button:hover {
    background-color: #ff1a1a;
  }
  