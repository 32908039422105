/* Styling for the popup */
.popup-overlay {
    margin-top: 2rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
  }
  
  .popup-content {
    background-color: white;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    width: 80%;
    max-width: 740px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-height: 90vh; 
    overflow-y: auto;
  }
  .popup-content-cards{
    margin-block: 1.5rem;
  }
  
  .mentor-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .mentor-card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .mentor-card h3 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .mentor-card p {
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  .book-btn {
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .book-btn:hover {
    background-color: #0056b3;
  }
  
  .mentor-btn{
    width: 100%;
    padding-inline: 40px;
    gap: 20px;
    justify-content: space-between;
  }
  .m-button{
    width: inherit;
  }

  .mentor-image {
    background-image: url("../../assets/hero-img.png");
    background-size: cover;
    height: 90px;
    width: 90px;
    margin-bottom: 10px;
    border-radius: 50%;
  }

  @media (max-width : 425px) {
    .mentor-btn{
      display: flex;
      flex-direction: column;
    }
  }