.sidebar {
    width: 15%;
    min-width: 65px;
    background-color: #f9f9f9;
    padding: 20px;
    overflow-y: scroll;
}

.logo h2 {
    font-size: 24px;
    margin-bottom: 40px;
}

.sidebar nav ul {
    width: 90%;
    list-style: none;
    padding: 0;
}

.sidebar nav ul li {
    margin: 20px 0;
    /* padding: 2px 10px; */
    font-size: 18px;
    border-radius: 8px;
    cursor: pointer;
}

.dis-none{
    display: none;
}

.sidebar a.active {
    color: rgb(25, 118, 210);
}

@media (max-width:590px) {
   .sidebar .sidebar-span{
        display: none;
    }
}