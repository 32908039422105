.delete-session-popup-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .delete-session-popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 300px;
    width: 90%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }

  .modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .btn {
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
  }
  
  .btn-no {
    background-color: #ccc;
  }
  
  .btn-yes {
    background-color: #e74c3c;
    color: #fff;
  }