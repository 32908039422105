.book-your-session{
    text-align: center;
    margin-top: 2rem;
    font-weight: 700;
}
.mentor-pricing{
    padding: 20px;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}
.mentor-pricing-card {
    /* border: 1px solid #ccc; */
    background: #ccc;
    padding: 20px;
    border-radius: 30px;
    gap: 30px;
    transition: background-color 0.3s ease;
}
.mentor-pricing-card:hover{
    background: #ccc;
    cursor: pointer;
}
.mentor-pricing-card:hover .price-value{
    background: blue;
    color: white;
}
.mentor-pricing-type{
    font-size: 20px;
    font-weight: 700;
}
.mentor-pricing-price{
    background: #efeff0;
    border: 1px solid #efeff0;
    padding: 15px;
    border-radius: 25px;
    gap: 3rem;
    align-items: center;
    transition: background-color 0.3s ease;
}
.mentor-pricing-card:hover .mentor-pricing-price{
    background-color: #ccc;
    border: 1px solid blue;
    box-sizing: border-box;
}
.mentor-pricing-price .flex{
    align-items: start;

}
.mentor-pricing-price .flex .icon{
    font-size: 40px;
}
.mentor-pricing-price .time{
    font-weight: 700;
}
.price-value{
    font-weight: 700;
    border: 1px solid black;
    padding-inline: 10px;
    padding-block: 5px;
    border-radius: 20px;
    transition: background-color 0.3s ease;
}

/* Responsive */
@media (max-width : 455px) {
    .mentor-pricing-price{
        padding: 8px;
        gap: 1rem;
    }
    .mentor-pricing{
        padding: 10px;
    }
}
@media (max-width : 455px) {
    .mentor-pricing-price{
        padding: 15px;
        gap: 1rem;
    }
}