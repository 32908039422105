/* .hero{
    font-family: Gilroy, -apple-system, BlinkMacSystemFont, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Arial, sans-serif !important;
} */
.hero {
  padding-block: 6rem;
  margin-left: 7rem;
  justify-content: space-between;
  gap: 0;
}
.hero-left {
  width: 42%;
  gap: 20px;
  align-items: center;
  text-align: center;
}
.hero-header {
  /* font-size: 3.5rem; */
  min-height: 12rem;
  font-weight: 700;
  line-height: 1.375;
}
.hero-para {
  font-size: 22px;
  line-height: 1.75rem;
  color: hsl(240 4% 40%);
  font-weight: 400;
  text-align: center;
}
.hero-btn {
  margin-top: 1rem;
  font-size: 1.5rem;
  gap: 10px;
}
.hero-btn {
  width: 100%;
}

/* Hero right */
.hero-right {
  gap: 20px;
  margin-right: 5rem;
}
.hero-card {
  align-items: center;
  gap: 10px;
  padding-inline: 20px;
  padding-block: 40px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.hero-image {
  /* background-color: red; */
  background-image: url("../../assets/hero-img.png");
  background-size: cover;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.hero-name {
  font-size: 1.3rem;
  font-weight: 600;
}
.hero-eoe span {
  font-weight: 600;
}
.hero-desc span {
  font-weight: 600;
}

/* Media query */
@media(max-width : 375px){
    .hero-header{
        /* font-size: 2.5rem !important; */
    }
}

@media (max-width: 580px) {
    .hero-right{
        flex-wrap: wrap;
        justify-content: center;
        margin-right: 0.1rem !important;
    }
    .hero-left{
        width: 100%;
    }
    .hero-btn{
        flex-wrap: wrap;
    }
    .hero{
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
    }
    .hero-header{
        /* font-size: 3rem; */
    }
}

@media (max-width: 768px) {
  .hero-right {
    margin-right: 3rem;
  }
  .hero{
    margin-left: 2rem;
  }
}
@media (max-width: 1024px) {
  .hero {
    flex-wrap: wrap;
    gap: 25px;
    margin-left: 4rem;
    justify-content: center;
  }
  .hero-left {
    width: 80%;
  }
}
@media (min-width: 1025px) {
  .hero {
    gap: 50px;
  }
}
