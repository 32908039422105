.testimonial {
    background-color: #f0f0f0;
    padding: 40px 30px;
    border-radius: 30px;
    margin: 10px 15px;
    max-width: 350px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 17px;
}

.testimonial p {
    margin-bottom: 35px;
    flex-grow: 1;
    text-align: left;
    font-family: poppins;
}

.testimonial-author {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.testimonial-author img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.testimonial-author h4 {
    margin: 10px 0 0;
    text-align: left;
}

.testimonial-author p {
    margin: 0;
    font-size: 0.75em;
    font-style: italic;
}
