.mentor-onboarding{
    margin-top: var(--navbar-height);
    padding-inline: 1rem;
    padding-block: 6rem;
    justify-content: center;
}
.m-onboarding{
    width: 500px;
    background-color: white;
    padding-inline: 40px;
    padding-block: 3rem;
    border-radius: 20px;
    text-align: center;
}
.mentor-header-text{
    margin-bottom: 2rem;
}
.mentor-form-group{
    align-items: start;
}
.mentor-form-group .flex{
    width: 100%;
}
.mentor-form-group input{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.mentor-form-group .otp-send{
    width: 70px;
}
.mentor-form{
    gap: 15px;
}
.mentor-next-btn{
    justify-content: space-between;
}
.mentor-next-btn1{
    justify-content: end;
}
.hide-elem{
    display: none;
}
.mentor-term-conditions .check-box{
    height: 12px;
    width: 12px;
    margin-right: 4px;
}
.mentor-term-conditions{
    justify-content: center;
    font-size: 14px;
    color: rgb(102 112 133/1);
}
.mentor-term-conditions a{
    color: #007bff;
}
.submit-btn-bg{
    background-color: rgb(98, 98, 223);
}
.submit-btn-bg:hover{
    cursor: not-allowed;
    background-color: rgb(98, 98, 223);;
}
.current-company-options{
    width: 100%;
    text-align: start ;
    height: 50px !important;
}