.booking-form {
  margin: auto;
  padding: 15px 20px;
  border-radius: 8px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.appointment-types {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.appointment-type {
  text-align: center;
  cursor: pointer;
  padding: 25px;
  border-radius: 20px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  background-color: #ffffff;
  color: black;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.299);
}

.appointment-type.selected {
  background-color: #007bff;
  color: white;
}

.appointment-type img {
  width: 50px;
  height: 50px;
  display: block;
  margin: auto;
}

.appointment-type span {
  display: block;
  margin-top: 8px;
  font-weight: bold;
}




input,
select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}


.profile-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.profile-item {
  border: 1px solid #ccc;
  padding: 30px 0;
  text-align: center;
}

.profile-item img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.profile-item p {
  margin: 5px 0 0px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: rgba(51, 51, 51, 0.76);
}

.profile-item h6 {
  font-family: Arial, Helvetica, sans-serif
}

/* Card.css */
.user-info {
  text-align: left;
  margin-bottom: 24px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.user-avatar img{
width: 120px;
height: 122px;
}
.user-info h2 {
  font-size: 1.8rem;
  margin-bottom: 8px;
}

.user-info p {
  font-size: 1rem;
  margin: 4px 0;
  color: #555;
}

.bookings {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 16px;
}

@media (max-width:1250px){
  .bookings{
    grid-template-columns: auto auto;
  }
}

@media (max-width:890px){
  .bookings{
    grid-template-columns: auto;
  }
}

.card {
  border-radius: 18px;
  padding: 16px 16px;
  text-align: left;
  background-color: #f8f9fa; /* Background color for card */
}

.card .card-img{
  max-height: 300px;
}

.card-title {
  font-size: 1.2rem;
  margin-bottom: 16px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  letter-spacing: -0.8px;
}

.card-buttons {
  display: flex;
  justify-content: space-between;
  background-color: #b1c6d5;
  border-radius: 18px;
  padding: 10px;
}

.card-buttons h6 {
  margin: 0;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  letter-spacing: -1px;
}

.card-buttons p {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: -1px;
  font-size: 0.87rem;
}

.card-buttons .price {
  border: 1px solid black;
  border-radius: 19px;
  padding: 0px 9px;
}

.btn:hover {
  opacity: 0.8;
}

