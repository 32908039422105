.mentor-profile-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
    overflow-y: auto;
  }
  
  .mentor-profile-popup{
    font-family: Inter, sans-serif;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 700px;
  }
  .mentor-profile-upper{
    height: 550px;
  }
  .mentor-profile-sidebar{
    margin-top: 3rem;
    gap: 20px;
    width: 90px;
    padding-inline: 1rem;
    font-size: 0.75rem;
    font-weight: 600;
    color:  rgb(153 153 153 / 1);
  }
  .mentor-profile-sidebar .flex-col{
    gap: 8px;
  }
  .mentor-profile-sidebar .ico{
    color: black;
    font-weight: 400 !important;
  }
  .vertical-divider{
    height: 100%;
    width: 0.5px;
    background-color: #ccc;
  }
  .horizontal-divider{
    width: 100%;
    height: 0.5px;
    background-color: #ccc;
  }
  .mentor-profile-main{
    margin: 2rem;
    width: 100%;
  }
  .header-text{
    text-align: start;
    font-size: 1.25rem;
    font-weight: 550;
    --tw-text-opacity: 1;
    color: rgb(17 17 17 / var(--tw-text-opacity));
  }
  /* mentor-profile-lower */
  .mentor-profile-lower{
    padding-block: 1.3rem;
    justify-content: end;
    gap: 20px;
    padding-inline: 2rem;
  }
  .mentor-profile-lower a{
    margin-left: 10px;
  }
  /* mentor-profile-main */
  .profile-scrollable{
    height: 450px;
    overflow-y: auto;
    padding-bottom: 30px;
  }
  .profile-scrollable::-webkit-scrollbar {
    display: none;
  }
  .background-cover{
    margin-top: 2rem;
    margin-bottom: 45px;
  }
  .background-cover-upper{
    height: 100px;
    background: linear-gradient(
    90deg,
    rgba(26, 19, 147, 1) 16%,
    rgba(24, 48, 223, 1) 30%,
    rgba(9, 71, 201, 1) 70%,
    rgba(8, 33, 91, 1) 96%
  );
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: start;
  }
#file-upload{
  display: none;
}
  .background-cover img{
    border-radius: 20px;
    position: relative;
    top: 45px;
    margin-left: 2rem;
    cursor: pointer;
  }
  .profile-input-field{
    border: 1px solid #ccc;
    transition: border-color 0.3s ease;
    border-radius: 8px;
    font-size: .875rem;
  }
  .profile-input-field:focus {
    border-color: blue; 
    outline: none; 
  }
  .profile-label-input{
    text-align: start;
    margin-top: 1rem;
  }
  .profile-text{
    font-size: 13px;
    color: #555;
    font-weight: 550;
    justify-content: space-between;
  }
  .profile-input-text-area{
    width: 100%;
    min-height: 80px;
    border-radius: 8px;
    padding: 8px;
    font-size: .875rem;
  }
  .profile-input-text-area:focus{
    border-color: blue; 
    outline: none; 
  }
  .mentor-location .flex{
    justify-content: space-between;
    gap: 20px;
  }
  .profile-text-select{
    width: 100%;
  }

  /* Education  */

  .education-section{
    text-align: start;
    margin-top: 2rem;
  }
  .education-label-input{
    align-items: start;
  }
  .profile-degree{
    margin-top: 1rem;
  }
  .profile-edu-date{
    width: 100%;
    align-items: start;
  }
  .profile-edu-date-select{
    background-color: red;
    width: 100%;
  }
  .mentor-edu-start-end-date{
    justify-content: space-between;
  }


  @media (max-width : 560px) {
    .vertical-divider{
      display: none;
    }
    .mentor-profile-sidebar{
      display: none;
    }
  }