.faqs {
    margin-block: 10rem;
    justify-content: space-evenly;
    display: flex;
    flex-wrap: wrap; /* Ensure that the items wrap */
}

.faqs-left, .faqs-right {
    width: 100%; /* Set to full width initially for responsiveness */
    padding-left: 2rem;
    margin-bottom: 2rem; /* Add some margin for spacing in wrap mode */
    flex-basis: 40%; /* Allow both sides to take 40% of the available space */
}

.faqs-right {
    padding-left: 0; /* Optional: Remove padding on the right */
}

.faqs-header {
    font-size: 54px;
    line-height: 70px;
    font-weight: 800;
    margin-bottom: 20px;
}

.faqs-para {
    font-size: 20px;
    line-height: 34px;
    font-weight: 400;
}

.faqs-ques {
    margin-block: 1.5rem;
    margin-inline: 1rem;
    font-size: 20px;
    line-height: 34px;
    font-weight: 700;
    gap: 5rem;
    justify-content: space-between;
}

.change-icon-color {
    color: blue;
}

.faqs-ques:hover {
    cursor: pointer;
}

.faqs-ans {
    margin-block: 1.5rem;
    margin-inline: 1rem;
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    transition: 0.8s ease-in-out;
}

/* Media queries */
@media (max-width : 425px) {
    .faqs-header{
        font-size: 42px !important;
    }
}
@media (max-width : 600px) {
    .faqs-header{
        font-size: 48px;
    }
}
@media (max-width: 768px) {
    .faqs{
        text-align: left;
    }
    .faqs-left{
        padding-left: 0rem !important;
    }
    .faqs-left, .faqs-right {
        flex-basis: 100%; /* Stack the elements vertically on smaller screens */
    }
}
@media (max-width : 1024px) {
    .faqs{
        flex-direction: column;
        padding-inline: 2rem;
    }
}